import { Injectable, inject } from '@angular/core';
import { Location } from '@angular/common';
import { LocalizeParser } from './localize-router.parser';
import { TranslocoService } from '@jsverse/transloco';
import { LocalizeRouterSettings } from '@owain/ngx-transloco-router/localize-router.config';
import { Routes } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DefaultLocalizeParser extends LocalizeParser {
  constructor() {
    const settings = inject(LocalizeRouterSettings);
    const location = inject(Location);
    const translate = inject(TranslocoService);

    super(translate, location, settings);

    this.locales = translate.getAvailableLangs() as string[];
    this.prefix = 'ROUTES.';
    this.escapePrefix = '!';
  }

  load(routes: Routes): Promise<any> {
    return new Promise((resolve: any) => {
      this.init(routes).then(resolve);
    });
  }
}
