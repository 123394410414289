import { ChangeDetectionStrategy, Component, inject, Signal, ViewContainerRef } from '@angular/core';
import { FastSvgComponent } from '@owain/fast-svg/fast-svg.component';
import { LocalizeRouterPipe } from '@owain/ngx-transloco-router/localize-router.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { LinkDirective } from '@owain/quicklink/link.directive';
import { CookiePreferencesService } from '../../services/cookie-preferences.service';
import { ApplicationUiStore } from '../../store/application-ui/application-ui.store';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FastSvgComponent, LocalizeRouterPipe, TranslocoPipe, RouterLink, LinkDirective],
})
export class AppFooterComponent {
  public readonly year: number = new Date().getFullYear();
  private readonly applicationUiStore: InstanceType<typeof ApplicationUiStore> = inject(ApplicationUiStore);
  public readonly theme: Signal<boolean> = this.applicationUiStore.theme;
  private readonly cookiePreferencesService: CookiePreferencesService = inject(CookiePreferencesService);
  private readonly viewContainerRef: ViewContainerRef = inject(ViewContainerRef);

  public openCookiePreferences() {
    this.cookiePreferencesService.open(this.viewContainerRef);
  }
}
